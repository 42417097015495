<template>
  <div v-if="!loadingData">
    <FormVue
      :edit="editForm"
      :title="titleForm"
      :product_name="productName"
      :code_sku="skuCode"
      :product_id="productId"
      v-if="displayForm"
      @closeModal="closeModal()"
    />
    <div class="title-page flex justify-between text-center items-center">
      <div class="flex items-center">
        <feather-icon
          icon="BriefcaseIcon"
          class="text-primary"
          svgClasses="h-5 w-6"
        ></feather-icon>
        <h3
          class="font-bold ml-2 text-primary"
          style="display: inline-block; font-size: 18px"
        >
          {{ $t("myProducts") }}
        </h3>
      </div>
      <div class="flex items-center">
        <buttonVue
          v-if="products.length"
          title="أضف منتج"
          @action="addProduct()"
        >
          <template #icon>
            <feather-icon
              icon="PlusCircleIcon"
              class="text-primary"
            ></feather-icon>
          </template>
        </buttonVue>
      </div>
    </div>
    <div v-if="products.length" class="products">
      <div class="product" v-for="(product, index) in products" :key="index">
        <p class="text">
          {{ product.name }}<span class="mx-4">|</span>SKU: {{ product.sku }}
        </p>
        <div class="actions">
          <feather-icon
            icon="TrashIcon"
            svgClasses="cursor-pointer text-danger stroke-current"
            @click.stop="(modalDelete = true), (productId = product.id)"
          />
          <feather-icon
            icon="EditIcon"
            svgClasses="cursor-pointer stroke-current"
            @click.stop="editProduct(product)"
          />
        </div>
      </div>
      <infinite-loading @infinite="infiniteHandler">
        <div slot="no-results" class="font-bold mb-3 text-primary">
          لا يوجد منتجات
        </div>
        <div slot="no-more" class="font-bold mb-3 text-primary">
          لا يوجد منتجات اخري
        </div>
      </infinite-loading>
    </div>
    <div v-else class="empty-products">
      <img
        loading="lazy"
        src="@/assets/images/elements/no product.png"
        alt="No Products"
      />
      <h6>عفواً {{ username }}, لا يوجد منتجات مضافة</h6>
      <p>من خلال هذة الصفحة يمكنك اضافة وتعديل وحذف المنتجات</p>
      <buttonVue title="أضف الأن" @action="addProduct()">
        <template #icon>
          <feather-icon
            icon="PlusCircleIcon"
            class="text-primary"
          ></feather-icon>
        </template>
      </buttonVue>
    </div>
    <ModalDeleteVue
      v-if="modalDelete"
      @closeModal="closeModalDelete()"
      @actionDelete="deleteOneProduct()"
      :loadingAction="loadingAction"
      description="هل انت متأكد من حذف المنتج"
    />
  </div>
  <div
    v-else
    class="flex items-center justify-center w-ful"
    style="height: 100vh"
  >
    <SpinnerColor2Vue />
  </div>
</template>

<script>
import buttonVue from "../../layouts/components/button.vue";
import FormVue from "./Form.vue";
import ModalVue from "../../layouts/components/Modal.vue";
import ModalDeleteVue from "../../layouts/components/ModalDelete.vue";
import { mapActions, mapGetters } from "vuex";
import SpinnerColor2Vue from "../components/table-components/SpinnerColor2.vue";
import notifyState from "../../mixins/notifyState";
export default {
  mixins: [notifyState],
  data() {
    return {
      displayForm: false,
      editForm: false,
      titleForm: "",
      productName: "",
      skuCode: "",
      modalDelete: false,
      username: JSON.parse(localStorage.getItem("userData")).name,
      loadingData: false,
      loadingAction: false,
      productId: null,
    };
  },
  computed: {
    ...mapGetters("products", [
      "products",
      "next_products_url",
      "error_msg",
      "success",
    ]),
  },
  components: {
    buttonVue,
    FormVue,
    ModalVue,
    ModalDeleteVue,
    SpinnerColor2Vue,
  },
  methods: {
    ...mapActions("products", ["fetchProducts", "deleteProduct"]),
    addProduct() {
      this.titleForm = "إضافة منتج";
      this.editForm = false;
      this.displayForm = true;
    },
    closeModal() {
      this.displayForm = false;
    },
    closeModalDelete() {
      this.modalDelete = false;
    },
    async deleteOneProduct() {
      this.loadingAction = true;
      await this.deleteProduct(this.productId);
      if (this.success === 1) {
        this.closeModalDelete();
        this.notifySuccess("deleteToast");
      } else {
        this.notifyFaild("faildDelete");
      }
      this.loadingAction = false;
    },
    editProduct(product) {
      this.titleForm = "تعديل منتج";
      this.editForm = true;
      this.displayForm = true;
      this.productName = product.name;
      this.skuCode = product.sku;
      this.productId = product.id;
    },
    async infiniteHandler($state) {
      if (!this.next_products_url) {
        $state.complete();
      } else {
        await this.fetchProducts({ isAppend: true, paginate: 4 });
        $state.loaded();
      }
    },
  },
  async created() {
    if (this.products.length == 0) {
      this.loadingData = true;
      await this.fetchProducts({ isAppend: true, paginate: 10 });
      this.loadingData = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_products.scss";
</style>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
.loading-default {
  border: 1px solid $mainColor1 !important;
  &::before {
    background-color: $mainColor1 !important;
  }
}
</style>
