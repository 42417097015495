<template>
  <div>
    <ModalAddVue :title="title" @closeModal="closeModal()">
      <template #body>
        <vs-alert :active="hasError" color="danger" icon="new_releases">
          <span>{{ error_msg }}</span>
        </vs-alert>
        <form>
          <div class="modal-input">
            <label for="">اسم المنتج</label>
            <input
              type="text"
              v-model="productName"
              v-validate="'required|min:3'"
              name="product_name"
            />
            <span style="font-size: 0.7rem" class="text-danger font-bold">{{
              errors.first("product_name")
            }}</span>
          </div>
          <div class="modal-input">
            <label for=""
              >رمز SKU
              <vx-tooltip
                extraClass="customize-tooltip"
                title="ما هو رمز SKU"
                text="كود مكون  من ارقام وحروف بيميز كل منتج 
عن الاخر ولا يمكن تكراره بين منتج والاخر,"
                position="left"
                delay=".2s"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.06004 5.99992C6.21678 5.55436 6.52614 5.17866 6.93334 4.93934C7.34055 4.70002 7.8193 4.61254 8.28483 4.69239C8.75035 4.77224 9.17259 5.01427 9.47676 5.3756C9.78093 5.73694 9.94741 6.19427 9.94671 6.66659C9.94671 7.99992 7.94671 8.66659 7.94671 8.66659M8.00004 11.3333H8.00671M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8.00004 14.6666C4.31814 14.6666 1.33337 11.6818 1.33337 7.99992C1.33337 4.31802 4.31814 1.33325 8.00004 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z"
                    stroke="#4400AF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </vx-tooltip>
            </label>
            <input
              type="text"
              v-model="codeSku"
              v-validate="'required|min:3'"
              name="code_sky"
              :disabled="edit"
              :class="edit ? 'disable-input' : ''"
            />
            <span style="font-size: 0.7rem" class="text-danger font-bold">{{
              errors.first("code_sky")
            }}</span>
          </div>
        </form>
      </template>
      <template #footer>
        <button
          v-if="!loading"
          @click="postOneProduct()"
          class="first-btn"
          :class="[{ 'active-btn': isFormValid }]"
          :disabled="!isFormValid"
        >
          {{ edit ? "حفظ الأن" : "أضف الأن" }}
        </button>
        <button class="first-btn loading-form" v-else>
          <spinner-color-2-vue />
        </button>
        <button class="second-btn" @click="closeModal()">الغاء</button>
      </template>
    </ModalAddVue>
  </div>
</template>

<script>
import ModalAddVue from "../../layouts/components/ModalAdd.vue";
import { mapActions, mapGetters } from "vuex";
import SpinnerColor2Vue from "../components/table-components/SpinnerColor2.vue";
import notifyState from "../../mixins/notifyState";
export default {
  mixins: [notifyState],
  data() {
    return {
      productName: "",
      codeSku: "",
      productId: null,
      loading: false,
      hasError: false,
    };
  },
  components: {
    ModalAddVue,
    SpinnerColor2Vue,
  },
  computed: {
    ...mapGetters("products", ["errors", "error_msg", "success"]),
    isFormValid() {
      if (!this.errors.any() && this.productName && this.codeSku) {
        return true;
      }
      return false;
    },
  },
  props: {
    product_name: {
      default: "",
    },
    code_sku: {
      default: "",
    },
    edit: {
      default: false,
    },
    title: {
      default: "",
    },
    product_id: {
      default: null,
    },
  },
  methods: {
    ...mapActions("products", ["postProduct", "updateProduct"]),
    closeModal() {
      this.$emit("closeModal");
    },
    postOneProduct() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.loading = true;
          if (!this.edit) {
            await this.postProduct({
              name: this.productName,
              sku: this.codeSku,
            });
          } else {
            await this.updateProduct({
              id: this.productId,
              name: this.productName,
              sku: this.codeSku,
            });
          }
          this.loading = false;
          if (this.success === 1) {
            if (!this.edit) this.notifySuccess("addToast");
            else this.notifySuccess("editToast");
            this.closeModal();
            this.hasError = false;
          } else {
            if (!this.edit) this.notifyFaild("faildAdd");
            else this.notifyFaild("faildEdit");
            this.hasError = true;
          }
        }
      });
    },
  },
  mounted() {
    if (this.edit) {
      this.productName = this.product_name;
      this.codeSku = this.code_sku;
      this.productId = this.product_id;
    }
  },
};
</script>

<style lang="scss" scoped>
.loading-form {
  height: 49px;
  cursor: wait !important;
  .loader {
    border: 2px solid #fff;
    border-top: 2px solid rgba(49, 0, 111, 0.1019607843);
  }
}
</style>
